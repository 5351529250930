<template>
    <div>
        <page-header
            :title="$t('pages.NotificationSettings.NotificationSettings')"
            :show-back="true"
            @onClose="$router.go(-1)"
        />

        <list-row-toggle
            :title="$t('pages.NotificationSettings.PushNotification')"
            v-model="isNotificationEnabled"
            :submitting="!isNotificationInitialized"
            @change="onToggleNotificationSetting"
        />
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { avenNotificationSetting, avenNotificationStatus } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import ListRowToggle from '@/components/ListRowToggle'
    export default {
        name: 'NotificationSetting',
        components: {
            PageHeader,
            ListRowToggle,
        },
        data() {
            return {
                loading: false,
                error: false,
                errorText: '',
                isNotificationEnabled: true,
                isNotificationInitialized: false,
            }
        },
        mounted: async function () {
            this.$logEvent('view_notification_settings')
            await this.getNotificationStatus()
        },
        methods: {
            onToggleNotificationSetting: async function (value) {
                this.$logEvent('toggle_notification_settings', { value })
                try {
                    this.loading = true
                    const NotificationResponse = await avenNotificationSetting(value)
                    const dataResponse = NotificationResponse.data
                    if (!dataResponse?.success) {
                        console.log(`this.notification: ${JSON.stringify(dataResponse.payload)}`)
                        this.loading = false
                    }
                } catch (error) {
                    this.loading = false
                    ApiErrorHandler(error)
                }
            },
            getNotificationStatus: async function () {
                try {
                    this.loading = true
                    const notificationStatus = await avenNotificationStatus()
                    const dataResponse = notificationStatus.data
                    if (!dataResponse?.success) {
                        this.loading = false
                        console.log(`this.notification: ${JSON.stringify(dataResponse.payload)}`)
                        return
                    }
                    this.loading = false
                    this.isNotificationEnabled = dataResponse.payload.isPushNotificationEnabled
                    this.$nextTick(() => (this.isNotificationInitialized = true))
                } catch (error) {
                    this.loading = false
                    ApiErrorHandler(error)
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/components/appBase';
</style>
